.block-driver-app {
  padding: 6rem 2rem;
}

.block-driver-app .block__text {
  margin: 2rem auto;
}

.block-driver-app__heading::after {
  display: none;
}

.block-driver-app .media {
  margin-bottom: 1.5rem;
}

.block-driver-app .media p {
  margin-top: 0;
}

.block-driver-app__image {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  order: 1;
}

.block-driver-app__image img {
  padding: 1.5rem 1rem 0;
  border-radius: 5px;
  margin: 0 auto 2rem;
  align-self: flex-start;
  width: 100%;
}

.block-driver-app__features {
  display: flex;
  flex-direction: column;
}

.block-driver-app__features .list {
  margin-bottom: 0;
}

.block-driver-app__features h2 {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .block-driver-app .block-hero__tagline {
    width: 80ch;
    margin: 0 auto;
  }

  .block-driver-app__heading {
    margin-top: 4rem;
    font-size: 4.5rem;
  }

  .block-driver-app__image {
    order: -1;
  }

  .block-driver-app__image img {
    align-self: center;
    margin: 0 auto;
    width: 400px;
  }

  .block-driver-app {
    padding-top: 0rem;
  }

  .block-driver-app__features {
    justify-content: center;
  }

  .block-driver-app__features h2 {
    text-align: left;
  }
}
