.callout {
  padding: 4rem;
  border-radius: 5px;
}

.callout--primary {
  background: var(--color-primary);
  color: #fff;
}

.callout__content {
  text-align: center;
}

.callout__heading {
  color: #fff;
  margin-top: 0;
  font-size: 3rem;
}

.callout .btn {
  justify-self: center;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .callout .grid--col-2 {
    grid-template-columns: 1fr auto;
  }

  .callout__content {
    text-align: left;
  }

  .callout .btn {
    justify-self: start;
    margin-left: 3rem;
  }
}
