.callout-quotes {
  transform: translateY(0rem);
}
.callout-quotes {
  padding: 1.5rem;
  margin-bottom: 3rem;
}

.callout-quotes .quote__text {
  font-size: 2.5rem;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .callout-quotes {
    transform: translateY(-5rem) !important;
  }
}
