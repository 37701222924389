.block {
  --padding-vertical: 8rem;
  padding: var(--padding-vertical) 2rem;
}

.block--dark {
  background: #000;
  color: #b9c3cf;
}

.block--dark .block__heading,
.block--dark .block__sub-heading {
  color: #fff;
}

.block__header {
  text-align: center;
}

.block__heading {
  font-size: 3rem;
  margin: 0;
}

.block__heading::after {
  content: '';
  display: block;
  width: 100px;
  margin: 2rem auto 3rem;
  border-bottom: 3px solid var(--color-primary);
}

.block__subheading {
  margin-top: 1rem;
  font-weight: 300;
  font-style: italic;
  font-size: 2.5rem;
}

.block--skewed-right {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);
}

.block--skewed-left {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .block__text {
    width: 70ch;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .block__text {
    width: 80ch;
  }
}
