/* Media Objects */
.media {
  display: flex;
  margin-bottom: 4rem;
}

.media__title {
  margin-top: 0;
}

.media__body {
  margin: 0 2rem;
}

.media__image {
  margin-top: 1rem;
}
