*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.prevent-animation-overflow {
  overflow: hidden;
}
