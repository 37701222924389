.block-registration-steps {
  background-color: #fdfdfd;
}

.block-registration-steps__image {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
  order: -1;
}

.block-registration-steps .media__body p {
  margin-bottom: 0;
  font-weight: 500;
}

.block-registration-steps .media__body small {
  font-style: italic;
}

@media screen and (min-width: 768px) {
  .block-registration-steps__image {
    order: 1;
    margin-top: 5rem;
    margin-left: 6rem;
    width: 90%;
  }
}
