.block-about__body p {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .block-about__body p {
    text-align: left;
    width: 70ch;
  }
}

@media screen and (min-width: 1024px) {
  .block-about__body p {
    width: 80ch;
  }

  .block-about {
    padding: 10rem 0 15rem;
  }
}
