.block-requirements__image {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4rem;
}

@media screen and (min-width: 768px) {
  .block-requirements__image {
    margin-top: 5rem;
    margin-right: 6rem;
    width: 90%;
  }
}
