.block-passenger-app .block__header {
  margin-bottom: 2rem;
}

.block-passenger-app .block__text {
  margin: 2rem auto;
}

.block-passenger-app .media {
  margin-bottom: 1.5rem;
}

.block-passenger-app .media .media__title {
  color: white;
}

.block-passenger-app .media p {
  margin-top: 0;
}

.block-passenger-app__image {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.block-passenger-app__image img {
  width: 100%;
}

.block-passenger-app__features {
  display: flex;
  flex-direction: column;
}

.block-passenger-app__features h2 {
  color: white;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .block-passenger-app__image img {
    width: 400px;
  }

  .block-passenger-app__features {
    justify-content: center;
  }

  .block-passenger-app__features h2 {
    text-align: left;
  }
}
