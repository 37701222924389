/* .block-hero {
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
} */

.block-hero__heading {
  text-shadow: 3px 3px 5px rgba(255, 255, 255, 0.2);
}

.block-hero__heading::after {
  display: none;
}

.block-hero__tagline {
  font-size: 2.1rem;
  margin: 3rem 0;
  color: #b9c3cf;
}

.block-hero__tagline .btn {
  margin-top: 3rem;
}

@media screen and (min-width: 768px) {
  .block-hero__heading {
    margin-top: 4rem;
    font-size: 4.5rem;
  }
}
