/* Quotes */
.quote {
  font-size: 3rem;
  font-style: italic;
  color: var(--color-body-darker);
  line-height: 1.3;
  text-align: center;
}

.quote__text::before {
  content: open-quote;
}

.quote__text::after {
  content: close-quote;
}
